const WarningIcon = () => {
    let width = 32;
    let height = 32;
    let viewBox = '0 0 32 32';

    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
        >
            <path
                d="M1.33301 28H30.6663L15.9997 2.66663L1.33301 28ZM5.95967 25.3333L15.9997 7.98663L26.0397 25.3333H5.95967ZM14.6663 21.3333H17.333V24H14.6663V21.3333ZM14.6663 13.3333H17.333V18.6666H14.6663V13.3333Z"
                fill="#FCBC40"
            />
        </svg>
    );
};

export default WarningIcon;
