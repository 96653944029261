import React, { useEffect, useState, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Badge,
    CircularProgress,
    Grid,
    Tab,
    Typography,
    MenuItem,
    Menu,
    Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import RestoreIcon from '@material-ui/icons/Restore';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { Button, Variant } from '@ndc/react-component-library';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Guid } from 'guid-typescript';
import styled from 'styled-components';
import { history } from 'src/routing';
import {
    ProjectInfo,
    NOT_FOUND,
    UploadingFile,
    FAILED_TO_GET_URL,
    ProjectOverview,
} from 'src/redux/Projects/interfaces';
import UsersTabContent from './UsersTabContent';
import {
    ProjectStatus,
    ProjectViewerTabValue,
    Scope,
} from 'src/constants/enums';
import LayoutUpdatesComponent from './LayoutUpdatesComponent';
import AddNewLayoutComponent from './AddNewLayoutComponent';
import ProjectFilesComponent from './ProjectFilesComponent';
import {
    DownloadUpdatesCommand,
    ArchiveOperationUpdatesCommand,
} from 'src/services/Dtos/DownloadUpdatesCommand';
import { AccessControl } from 'src/components/AccessControl';
import { OperationSettingTemplate } from 'src/redux/OperationSettings/interfaces';
import { isGuidEmpty } from 'src/helpers/utils';
import theme from 'src/theme';
import useTranslation from 'src/hooks/useTranslation';
import DeleteProjectModal from './DeleteProjectModal';
import { modalActions } from 'src/redux/Modals';
import { useGetNewLayoutUpdatesQuery } from 'src/services/layoutService';
import { LayoutUpdateDTO } from 'src/services/Dtos/LayoutUpdate';

const useStyles = makeStyles((theme) => ({
    tabList: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    tab: {
        minWidth: theme.spacing(14),
        paddingBottom: 0,
        '&:nth-child(1)': {
            paddingLeft: 0,
        },
    },
    tabPanel: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: theme.spacing(6),
    },
    badge: {
        '& .MuiBadge-badge': {
            top: 5,
            right: -5,
        },
    },
}));

const StyledDivider = styled(Divider)`
    background-color: ${theme.palette.neutral.gainsboro};
    margin: 10px 16px;
`;

interface IMenuItemWithIcon {
    onClick: any;
    label: string;
    icon: any;
    dataTestId?: string;
}

export type Props = {
    selectedProject: ProjectInfo | undefined | typeof NOT_FOUND;
    subscriptionId: Guid;
    uploadingLayoutFiles: UploadingFile[];
    layoutFileDownloadLink: URL | undefined | typeof FAILED_TO_GET_URL;
    operationSettings: OperationSettingTemplate[];
};

export type Actions = {
    CreateLayout: (
        subscriptionId: Guid,
        siteName: string,
        systemName: string,
        layoutName: string,
        projectId: Guid,
        createdCallback: (projectId?: string) => void
    ) => void;
    GetLayoutFileDownloadLink: (
        subscriptionId: Guid,
        projectId: string,
        layoutId: string,
        layoutFileId: string
    ) => void;
    GetProject: (subscriptionId: Guid, guid: string) => void;
    ResetSelectedProject: () => void;
    ResetLayoutFileDownloadLink: () => void;
    UploadLayoutDb: (
        subscriptionId: Guid,
        projectId: string,
        layoutId: string,
        layoutDbFile: File,
        wefFile: File
    ) => void;
    UploadDwg: (
        subscriptionId: Guid,
        projectId: string,
        layoutId: string,
        dwgFile: File
    ) => void;
    UploadExternalStationOperation: (
        subscriptionId: Guid,
        projectId: string,
        layoutId: string,
        externalStationOperation: File
    ) => void;
    DeleteExternalStationOperation: (
        subscriptionId: Guid,
        projectId: string,
        layoutId: string,
        deleteCallBack: (status?: boolean) => void
    ) => void;
    DownloadLayoutUpdates: (
        subscriptionId: Guid,
        projectId: string,
        layoutId: string,
        downloadUpdatesCommand: DownloadUpdatesCommand
    ) => void;
    MarkOperationUpdatesAsDownloaded: (
        subscriptionId: Guid,
        projectId: string,
        layoutId: string,
        archiveUpdatesCommand: ArchiveOperationUpdatesCommand
    ) => void;
    GetOperationSettings: (subscriptionId: Guid, projectId: string) => any;
    EditProject: (
        subscriptionId: Guid,
        project: ProjectOverview,
        editedCallback: (projectId?: string) => void
    ) => void;
    DeleteProject: (
        subscriptionId: Guid,
        projectId: string,
        editedCallback: (status: boolean) => void
    ) => void;
    showModalWithContents: (
        id: Guid,
        heading: string,
        content: ReactNode
    ) => void;
};

const ProjectViewer = ({
    layoutFileDownloadLink,
    selectedProject,
    subscriptionId,
    uploadingLayoutFiles,
    operationSettings,
    CreateLayout,
    DownloadLayoutUpdates,
    MarkOperationUpdatesAsDownloaded,
    GetLayoutFileDownloadLink,
    GetProject,
    ResetLayoutFileDownloadLink,
    ResetSelectedProject,
    UploadDwg,
    UploadExternalStationOperation,
    DeleteExternalStationOperation,
    UploadLayoutDb,
    GetOperationSettings,
    EditProject,
    DeleteProject,
    showModalWithContents,
}: Props & Actions) => {
    const NavigateTo = (path: string) => {
        history.push(path);
    };
    const classes = useStyles();
    const dispatch = useDispatch();
    const { projectGuid } = useParams<{
        projectGuid: string;
    }>();

    const [projectTabValue, setProjectTabValue] = useState(
        ProjectViewerTabValue.Files
    );
    const [isPageLoad, setIsPageLoad] = useState(true);
    const [operationCodeFormat, setOperationCodeFormat] =
        useState('Hexadecimal');
    const [createLayoutInProgress, setCreateLayoutInProgress] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const getTranslation = useTranslation();
    const isCreatingProject = projectGuid == undefined;
    const [projectStatus, setProjectStatus] = useState(
        !isCreatingProject && selectedProject != NOT_FOUND
            ? selectedProject?.status
            : ProjectStatus.Ongoing
    );
    const [activeModalId, setActiveModalId] = useState<Guid>(Guid.create());

    const projectId =
        selectedProject === NOT_FOUND ? undefined : selectedProject?.id;
    const layoutId =
        selectedProject === NOT_FOUND ? undefined : selectedProject?.layoutId;

    const getNewLayoutUpdatesQuery = useGetNewLayoutUpdatesQuery(
        {
            subscriptionId: subscriptionId?.toString(),
            projectId: projectId!,
            layoutId: layoutId!,
        },
        {
            skip: !subscriptionId || !projectId || !layoutId,
        }
    );

    const getNewUpdatesCount = (data?: LayoutUpdateDTO) => {
        const newPointUpdatesCount = data?.points?.length ?? 0;
        const newSegmentUpdatesCount = data?.segments?.length ?? 0;
        const newDrawingLineUpdatesCount = data?.drawingLines?.length ?? 0;
        const newOperationUpdatesCount = data?.operations?.length ?? 0;
        return (
            newPointUpdatesCount +
            newOperationUpdatesCount +
            newSegmentUpdatesCount +
            newDrawingLineUpdatesCount
        );
    };
    const newUpdatesCount = getNewUpdatesCount(getNewLayoutUpdatesQuery?.data);
    useEffect(() => {
        ResetSelectedProject();
    }, []);

    useEffect(() => {
        if (subscriptionId && !isGuidEmpty(subscriptionId)) {
            GetProject(subscriptionId, projectGuid);
            GetOperationSettings(subscriptionId, projectGuid);
        }
    }, [subscriptionId, projectStatus]);

    useEffect(() => {
        if (selectedProject) {
            if (isPageLoad) {
                setIsPageLoad(false);
                if (selectedProject != NOT_FOUND) {
                    let tab = ProjectViewerTabValue.Files;
                    if (
                        selectedProject.layoutFiles &&
                        selectedProject.layoutFiles.length > 0
                    ) {
                        tab = ProjectViewerTabValue.Updates;
                    }
                    setProjectTabValue(tab);
                }
            }
        } else {
            setIsPageLoad(true);
        }
    }, [selectedProject]);

    useEffect(() => {
        if (operationSettings) {
            const operationSettingsTemplate = operationSettings[0];
            setOperationCodeFormat(
                operationSettingsTemplate?.operationCodeFormat
            );
        }
    }, [operationSettings]);

    const createdCallback = (projectId?: string) => {
        setCreateLayoutInProgress(false);
        if (projectId !== undefined)
            GetProject(subscriptionId as Guid, projectId);
    };
    const handleCreateLayout = (
        site: string,
        system: string,
        layout: string
    ) => {
        setCreateLayoutInProgress(true);
        CreateLayout(
            subscriptionId as Guid,
            site,
            system,
            layout,
            Guid.parse(projectGuid),
            createdCallback
        );
    };

    const handleMenuOpen = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleUploadLayoutDb = (
        subscriptionId: Guid,
        projectId: string,
        layoutId: string,
        layoutDbFile: File,
        wefFile: File
    ) => {
        getNewLayoutUpdatesQuery
            .refetch()
            .unwrap()
            .then((data) => {
                const newUpdatesCount = getNewUpdatesCount(data);
                if (newUpdatesCount === 0) {
                    UploadLayoutDb(
                        subscriptionId,
                        projectId,
                        layoutId,
                        layoutDbFile,
                        wefFile
                    );
                } else {
                    //TODO: Replace alert with modal error message
                    alert(
                        'Unable to upload file because there are new updates available for download'
                    );
                }
            });
    };

    const handleEditProjectClick = () => {
        handleMenuClose();
        const finishedCallback = (projectId?: string) => {
            if (projectId !== undefined) {
                setProjectStatus(
                    selectedProject != NOT_FOUND &&
                        selectedProject != null &&
                        selectedProject.status === ProjectStatus.Ongoing
                        ? ProjectStatus.Finished
                        : ProjectStatus.Ongoing
                );
            }
        };
        if (selectedProject != NOT_FOUND && selectedProject != null) {
            const projectOverview: ProjectOverview = {
                companyId: selectedProject?.companyId,
                projectName: selectedProject?.commissioningProject,
                projectId: selectedProject?.id,
                status:
                    selectedProject.status === ProjectStatus.Ongoing
                        ? ProjectStatus.Finished
                        : ProjectStatus.Ongoing,
            };
            EditProject(
                subscriptionId as Guid,
                projectOverview,
                finishedCallback
            );
        }
    };

    const onDeleteProjectClick = () => {
        handleMenuClose();
        setActiveModalId(Guid.create());
        showModalWithContents(
            activeModalId,
            getTranslation('Projects.DeleteProject.Label'),
            <DeleteProjectModal
                selectedProject={selectedProject}
                deleteProject={DeleteProject}
                subscriptionGuid={subscriptionId}
                closeModal={() =>
                    dispatch(modalActions.CloseModal(activeModalId))
                }
            />
        );
    };

    const MenuItemWithIcon = ({
        onClick,
        label,
        icon,
        dataTestId,
    }: IMenuItemWithIcon) => (
        <MenuItem
            style={{ display: 'flex', gap: '10px' }}
            onClick={onClick}
            data-testid={dataTestId}
        >
            {icon}
            <Typography variant="inherit">{label}</Typography>
        </MenuItem>
    );

    const propertyViewer = (title: string, value: string) => {
        return (
            <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                    {title}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {value}
                </Typography>
            </Grid>
        );
    };

    const layoutInfoContainer = selectedProject &&
        selectedProject != NOT_FOUND &&
        selectedProject.layoutId != null &&
        selectedProject.layoutId != Guid.EMPTY && (
            <Grid container spacing={6} justifyContent="flex-start">
                {propertyViewer('Site name', selectedProject.siteName!)}
                {propertyViewer('System name', selectedProject.systemName!)}
                {propertyViewer('Layout name', selectedProject.layoutName!)}
            </Grid>
        );

    if (!selectedProject)
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Typography variant="h3" style={{ marginRight: '20px' }}>
                    Loading Project...
                </Typography>
                <CircularProgress color="secondary" />
            </div>
        );
    if (selectedProject == NOT_FOUND)
        return (
            <Typography variant="h6" style={{ marginRight: '20px' }}>
                {'Project with id ' +
                    projectGuid +
                    ' was not found on the server'}
            </Typography>
        );

    const deletedCallback = (status?: boolean) => {
        status && GetProject(subscriptionId, projectGuid);
    };

    return (
        <React.Fragment>
            <div
                style={{
                    marginTop: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '30px',
                    }}
                >
                    <div>
                        <Typography variant="h3" gutterBottom>
                            {selectedProject.commissioningProject}
                        </Typography>
                        <Grid container spacing={6} justifyContent="flex-start">
                            {propertyViewer(
                                'Status',
                                ProjectStatus[selectedProject.status]
                            )}
                            {propertyViewer('Company', selectedProject.company)}
                        </Grid>
                    </div>
                    <div>
                        <AccessControl
                            scope={Scope.Project}
                            id={selectedProject.id}
                            allowedPermission="editProject"
                        >
                            <>
                                <Button
                                    aria-controls="dropdown-menu"
                                    aria-haspopup="true"
                                    onClick={handleMenuOpen}
                                    data-testid="edit-project-button"
                                    variant={Variant.secondary}
                                    style={{
                                        height: '40px',
                                        width: 'max-content',
                                    }}
                                >
                                    {getTranslation(
                                        'General.EditProject.Label'
                                    )}
                                    <ExpandMoreIcon />
                                </Button>
                                <Menu
                                    id="dropdown-menu"
                                    anchorEl={menuAnchorEl}
                                    open={Boolean(menuAnchorEl)}
                                    onClose={handleMenuClose}
                                    transformOrigin={{
                                        vertical: -95,
                                        horizontal: 92,
                                    }}
                                >
                                    <div>
                                        <MenuItemWithIcon
                                            onClick={() =>
                                                subscriptionId &&
                                                NavigateTo(
                                                    `/subscriptions/${subscriptionId}/edit-project/${projectGuid}`
                                                )
                                            }
                                            label={getTranslation(
                                                'General.EditNameAndCompany.Label'
                                            )}
                                            icon={<EditIcon />}
                                            dataTestId="edit-name-company-button"
                                        />
                                        <MenuItemWithIcon
                                            onClick={handleEditProjectClick}
                                            label={
                                                selectedProject.status ===
                                                ProjectStatus.Ongoing
                                                    ? getTranslation(
                                                          'General.SetToFinished.Label'
                                                      )
                                                    : getTranslation(
                                                          'General.SetToOngoing.Label'
                                                      )
                                            }
                                            icon={
                                                selectedProject.status ===
                                                ProjectStatus.Ongoing ? (
                                                    <CheckIcon />
                                                ) : (
                                                    <RestoreIcon />
                                                )
                                            }
                                            dataTestId="set-to-finish-ongoing-button"
                                        />
                                        <StyledDivider />
                                        <MenuItemWithIcon
                                            onClick={onDeleteProjectClick}
                                            label={getTranslation(
                                                'General.DeleteProject.Label'
                                            )}
                                            icon={<DeleteOutlinedIcon />}
                                            dataTestId="delete-project-button"
                                        />
                                    </div>
                                </Menu>
                            </>
                        </AccessControl>
                    </div>
                </div>
                <TabContext value={projectTabValue}>
                    <TabList
                        className={classes.tabList}
                        onChange={(option, value) => setProjectTabValue(value)}
                    >
                        <Tab
                            className={classes.tab}
                            data-testid="updates-tab"
                            disabled={!selectedProject?.layoutId}
                            label={
                                <Badge
                                    className={classes.badge}
                                    overlap="rectangular"
                                    variant="dot"
                                    invisible={newUpdatesCount === 0}
                                    color="secondary"
                                >
                                    Updates
                                </Badge>
                            }
                            value={ProjectViewerTabValue.Updates}
                        />
                        <Tab
                            className={classes.tab}
                            data-testid="files-tab"
                            label={'Files'}
                            value={ProjectViewerTabValue.Files}
                        />
                        <Tab
                            className={classes.tab}
                            data-testid="users-tab"
                            label={'Users'}
                            value={ProjectViewerTabValue.Users}
                        />
                    </TabList>
                    <TabPanel
                        value={ProjectViewerTabValue.Updates}
                        className={classes.tabPanel}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <LayoutUpdatesComponent
                                MarkOperationUpdatesAsArchive={(
                                    archiveUpdatesCommand: ArchiveOperationUpdatesCommand
                                ) =>
                                    MarkOperationUpdatesAsDownloaded(
                                        subscriptionId as Guid,
                                        selectedProject.id,
                                        selectedProject.layoutId!,
                                        archiveUpdatesCommand
                                    )
                                }
                                DownloadLayoutUpdates={(
                                    downloadUpdatesCommand: DownloadUpdatesCommand
                                ) =>
                                    DownloadLayoutUpdates(
                                        subscriptionId as Guid,
                                        selectedProject.id,
                                        selectedProject.layoutId!,
                                        downloadUpdatesCommand
                                    )
                                }
                                hasUploadedWefFile={
                                    !!selectedProject.wefFileInfo
                                }
                                SetProjectTabValue={(
                                    tab: ProjectViewerTabValue
                                ) => setProjectTabValue(tab)}
                                operationCodeFormat={operationCodeFormat}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel
                        value={ProjectViewerTabValue.Files}
                        className={classes.tabPanel}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {selectedProject.layoutId != null &&
                            selectedProject.layoutId != Guid.EMPTY ? (
                                <>
                                    {layoutInfoContainer}

                                    {
                                        <ProjectFilesComponent
                                            selectedProject={selectedProject}
                                            uploadingLayoutFiles={
                                                uploadingLayoutFiles
                                            }
                                            layoutFileDownloadLink={
                                                layoutFileDownloadLink
                                            }
                                            uploadDisabled={newUpdatesCount > 0}
                                            UploadExternalStationOperation={(
                                                file
                                            ) =>
                                                UploadExternalStationOperation(
                                                    subscriptionId as Guid,
                                                    selectedProject.id,
                                                    selectedProject.layoutId!,
                                                    file
                                                )
                                            }
                                            DeleteExternalStationOperation={() =>
                                                DeleteExternalStationOperation(
                                                    subscriptionId as Guid,
                                                    selectedProject.id,
                                                    selectedProject.layoutId!,
                                                    deletedCallback
                                                )
                                            }
                                            UploadDwg={(file) =>
                                                UploadDwg(
                                                    subscriptionId as Guid,
                                                    selectedProject.id,
                                                    selectedProject.layoutId!,
                                                    file
                                                )
                                            }
                                            UploadLayoutDb={(
                                                layoutDbFile,
                                                wefFile
                                            ) =>
                                                handleUploadLayoutDb(
                                                    subscriptionId as Guid,
                                                    selectedProject.id,
                                                    selectedProject.layoutId!,
                                                    layoutDbFile,
                                                    wefFile
                                                )
                                            }
                                            GetLayoutFileDownloadLink={(
                                                layoutFileId
                                            ) =>
                                                GetLayoutFileDownloadLink(
                                                    subscriptionId as Guid,
                                                    selectedProject.id,
                                                    selectedProject.layoutId!,
                                                    layoutFileId
                                                )
                                            }
                                            ResetLayoutFileDownloadLink={
                                                ResetLayoutFileDownloadLink
                                            }
                                        />
                                    }
                                </>
                            ) : (
                                <AddNewLayoutComponent
                                    CreateLayout={handleCreateLayout}
                                    createLayoutInProgress={
                                        createLayoutInProgress
                                    }
                                />
                            )}
                        </div>
                    </TabPanel>
                    <TabPanel
                        value={ProjectViewerTabValue.Users}
                        className={classes.tabPanel}
                    >
                        <UsersTabContent
                            subscriptionId={subscriptionId as Guid}
                            selectedProject={selectedProject}
                        />
                    </TabPanel>
                </TabContext>
            </div>
        </React.Fragment>
    );
};

export default ProjectViewer;
