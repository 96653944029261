import { useState } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { filter, take } from 'lodash';
import { LayoutFileType } from 'src/constants/layoutFileConstants';
import useTranslation from 'src/hooks/useTranslation';
import {
    FAILED_TO_GET_URL,
    LayoutFile,
    ProjectInfo,
    UploadingFile,
    WefFileInfo,
} from 'src/redux/Projects/interfaces';
import LayoutFileComponent from './LayoutFileComponent';
import ExternalOperationFileHandler from './ExternalOperationFileHandler';
import { RichPopper } from 'src/components/Popper';
import InfoIcon from 'src/assets/icons/InfoIcon';
import styled from 'styled-components';

const PopperTextContainer = styled.div`
    width: 320px;
    height: fit-content;
`;

export type Props = {
    selectedProject: ProjectInfo;
    uploadingLayoutFiles: UploadingFile[];
    layoutFileDownloadLink: URL | undefined | typeof FAILED_TO_GET_URL;
    uploadDisabled: boolean;
};

export type Actions = {
    GetLayoutFileDownloadLink: (layoutFileId: string) => void;
    ResetLayoutFileDownloadLink: () => void;
    UploadLayoutDb: (layoutDbFile: File, wefFile: File) => void;
    UploadDwg: (dwgFile: File) => void;
    UploadExternalStationOperation: (externalStationOperation: File) => void;
    DeleteExternalStationOperation: () => void;
};

export type FileList = (LayoutFile | WefFileInfo)[];

const ProjectFilesComponent = ({
    selectedProject,
    uploadingLayoutFiles,
    layoutFileDownloadLink,
    uploadDisabled,
    GetLayoutFileDownloadLink,
    ResetLayoutFileDownloadLink,
    UploadLayoutDb,
    UploadDwg,
    UploadExternalStationOperation,
    DeleteExternalStationOperation,
}: Props & Actions) => {
    const getTranslation = useTranslation();

    const [popperOpen, setPopperOpen] = useState(false);
    const handleLayoutDbSubmit = (files: File[]) => {
        UploadLayoutDb(files[0], files[1]);
    };

    const handleDwgSubmit = (files: File[]) => {
        UploadDwg(files[0]);
    };

    const externalOperationHandler = ExternalOperationFileHandler({
        UploadExternalStationOperation,
    });

    const fileOverview = (
        title: string,
        fileType: LayoutFileType,
        handleSubmit: (files: File[]) => void
    ) => {
        const fileList = take(
            filter(selectedProject.layoutFiles, {
                fileType: fileType,
            }),
            1
        ) as FileList;

        const UpdatesPopupTips = (
            <PopperTextContainer>
                {getTranslation(
                    'ProjectViewer.ExternalStationOperationsFileUpload.Popper.Text'
                )}
            </PopperTextContainer>
        );

        if (
            selectedProject.wefFileInfo &&
            fileList &&
            fileType === LayoutFileType.LayoutDatabase
        )
            fileList.push(selectedProject.wefFileInfo);
        return (
            <div
                style={{
                    marginTop: '40px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                    }}
                >
                    <Typography variant="h6" style={{ marginRight: '10px' }}>
                        {title}
                    </Typography>
                    {fileType === LayoutFileType.ExternalStationOperations && (
                        <RichPopper
                            content={UpdatesPopupTips}
                            open={popperOpen}
                            placement="bottom"
                            onClose={() => setPopperOpen(false)}
                        >
                            <IconButton
                                data-testid="external-station-upload-popper"
                                onClick={() => setPopperOpen(!popperOpen)}
                                style={{ padding: '0px', marginTop: '3px' }}
                            >
                                <InfoIcon />
                            </IconButton>
                        </RichPopper>
                    )}
                </div>
                <LayoutFileComponent
                    fileList={fileList}
                    uploadingLayoutFiles={filter(uploadingLayoutFiles, {
                        fileType: fileType,
                    })}
                    fileType={fileType}
                    layoutFileDownloadLink={layoutFileDownloadLink}
                    uploadDisabled={
                        fileType === LayoutFileType.LayoutDatabase &&
                        uploadDisabled
                    }
                    uploadHandler={handleSubmit}
                    GetLayoutFileDownloadLink={GetLayoutFileDownloadLink}
                    ResetLayoutFileDownloadLink={ResetLayoutFileDownloadLink}
                    projectId={selectedProject.id}
                    DeleteExternalStationOperation={
                        DeleteExternalStationOperation
                    }
                />
            </div>
        );
    };

    return (
        <>
            {fileOverview(
                getTranslation('ProjectViewer.Files.LayoutFiles'),
                LayoutFileType.LayoutDatabase,
                handleLayoutDbSubmit
            )}
            {fileOverview(
                getTranslation('ProjectViewer.Files.BackgroundDrawing'),
                LayoutFileType.BackgroundDrawing,
                handleDwgSubmit
            )}
            {fileOverview(
                getTranslation('ProjectViewer.Files.ExternalStationOperations'),
                LayoutFileType.ExternalStationOperations,
                externalOperationHandler.handleExternalOperationFileSubmit
            )}
        </>
    );
};

export default ProjectFilesComponent;
