import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import ajvErrors from 'ajv-errors';

// @ts-ignore
import { parse } from 'json-source-map';

interface StationOperation {
    stationId: string | number;
}

interface ValidationResult {
    isValid: boolean;
    errors: Array<{
        message: string;
        path: string;
        row: number;
        col: number;
        errorObject: {
            error: string;
            path: string;
            row: number;
            col: number;
        };
    }>;
}

const createValidator = (schema: any) => {
    const ajv = new Ajv({ allErrors: true, strict: false });
    addFormats(ajv);
    ajvErrors(ajv);
    const validate = ajv.compile(schema);

    return (data: Record<string, any>, rawJson: string): ValidationResult => {
        const { pointers } = parse(rawJson);
        const isValid = validate(data);
        const errorMap = new Map<
            string,
            {
                path: string;
                row: number;
                col: number;
                messages: string[];
            }
        >();

        if (!isValid) {
            (validate?.errors ?? [])
                .filter((err) => err.message !== 'must match "else" schema')
                .filter((err) => err.message !== 'must match "then" schema')
                .forEach((err) => {
                    const pointer = pointers[err.instancePath || ''];
                    const row = pointer?.value?.line + 1 || 1;
                    const col = pointer?.value?.column + 1 || 1;

                    const key = err.instancePath;
                    let errorMessage = err.message ?? 'Unknown error';

                    const operationCodeFormat = (data as any)
                        ?.operationCodeFormat;

                    if (
                        err.keyword === 'type' &&
                        err.params?.type === 'integer' &&
                        key.includes('/operations/') &&
                        key.endsWith('/code') &&
                        operationCodeFormat === 'decimal'
                    ) {
                        errorMessage =
                            'Code must be an integer when format is decimal';
                    } else if (
                        err.keyword === 'type' &&
                        key.includes('/operations/') &&
                        key.endsWith('/code') &&
                        operationCodeFormat === 'hexadecimal'
                    ) {
                        errorMessage =
                            'Code must be a string when format is hexadecimal';
                    } else if (
                        err.keyword === 'pattern' &&
                        err.params?.pattern === '^[0-9a-fA-F]+$' &&
                        key.includes('/operations/') &&
                        key.endsWith('/code') &&
                        operationCodeFormat === 'hexadecimal'
                    ) {
                        errorMessage = 'Code must be a valid hexadecimal value';
                    } else if (
                        err.keyword === 'oneOf' &&
                        key.includes('/operations/') &&
                        key.endsWith('/code')
                    ) {
                        errorMessage =
                            'Code must be either an integer (decimal) or a valid hexadecimal string';
                    }

                    // String length validation for hexadecimal and decimal formats
                    if (key.includes('/operations/') && key.endsWith('/code')) {
                        const codeValue = (data as any)?.operations?.[key];
                        if (operationCodeFormat === 'hexadecimal') {
                            // Check the length for hexadecimal code
                            if (
                                typeof codeValue === 'string' &&
                                codeValue.length > 4
                            ) {
                                errorMessage =
                                    'Hexadecimal code must have a maximum length of 4 characters';
                            }
                        } else if (operationCodeFormat === 'decimal') {
                            // Check the length for decimal code (max 5 digits)
                            const decimalValue = Number(codeValue);
                            if (decimalValue > 99999) {
                                errorMessage =
                                    'Decimal code must have a maximum length of 5 digits';
                            }
                        }
                    }

                    if (!errorMap.has(key)) {
                        errorMap.set(key, {
                            path: err.instancePath,
                            row,
                            col,
                            messages: [errorMessage],
                        });
                    }
                });
        }

        // Validate stationOperations parameters against operations parameters
        if (data.operations && data.stationOperations) {
            const validParamValues = new Set<string>();

            data.operations.forEach((operation: any) => {
                Object.values(operation.parameters || {}).forEach((value) => {
                    if (typeof value === 'string') {
                        validParamValues.add(value);
                    }
                });
            });

            data.stationOperations.forEach(
                (stationOp: any, stationIndex: number) => {
                    stationOp.operations.forEach(
                        (operation: any, opIndex: number) => {
                            Object.keys(operation.parameters || {}).forEach(
                                (paramKey) => {
                                    const isValidParamKey =
                                        /^OMPLC\.[a-zA-Z\d]+$/.test(paramKey) ||
                                        ['PARAM1', 'PARAM2'].includes(
                                            paramKey
                                        ) ||
                                        validParamValues.has(paramKey);

                                    if (!isValidParamKey) {
                                        const path = `/stationOperations/${stationIndex}/operations/${opIndex}/parameters/${paramKey}`;
                                        const pointer = pointers[path];
                                        const row =
                                            pointer?.value?.line + 1 || 1;
                                        const col =
                                            pointer?.value?.column + 1 || 1;

                                        errorMap.set(path, {
                                            path,
                                            row,
                                            col,
                                            messages: [
                                                `Invalid parameter key "${paramKey}" in stationOperations.`,
                                            ],
                                        });
                                    }
                                }
                            );
                        }
                    );
                }
            );
        }

        // Check for duplicate stationId in stationOperations array
        if (Array.isArray(data?.stationOperations)) {
            const stationIdOccurrences = new Map<
                string | number,
                { path: string; row: number; col: number }[]
            >();

            data.stationOperations.forEach(
                (operation: StationOperation, index: number) => {
                    const path = `/stationOperations/${index}/stationId`;
                    const pointer = pointers[path];
                    const row = pointer?.value?.line + 1 || 1;
                    const col = pointer?.value?.column + 1 || 1;

                    if (operation.stationId !== undefined) {
                        if (!stationIdOccurrences.has(operation.stationId)) {
                            stationIdOccurrences.set(operation.stationId, []);
                        }
                        stationIdOccurrences
                            .get(operation.stationId)!
                            .push({ path, row, col });
                    }
                }
            );

            stationIdOccurrences.forEach((locations, stationId) => {
                if (locations.length > 1) {
                    locations.forEach(({ path, row, col }) => {
                        errorMap.set(path, {
                            path,
                            row,
                            col,
                            messages: [
                                `Duplicate stationId found: ${stationId}`,
                            ],
                        });
                    });
                }
            });
        }

        // Check for duplicate code AND operationId in operations array
        if (Array.isArray(data?.operations)) {
            const codeOperationIdOccurrences = new Map<
                string,
                {
                    pathCode: string;
                    pathOperationId: string;
                    row: number;
                    col: number;
                }[]
            >();
            const operationIdOccurrences = new Map<
                string,
                { path: string; row: number; col: number }[]
            >();

            data.operations.forEach((operation: any, index: number) => {
                const pathCode = `/operations/${index}/code`;
                const pathOperationId = `/operations/${index}/operationId`;
                const pointerCode = pointers[pathCode];
                const pointerOperationId = pointers[pathOperationId];
                const rowCode = pointerCode?.value?.line + 1 || 1;
                const colCode = pointerCode?.value?.column + 1 || 1;

                if (
                    operation.code !== undefined &&
                    operation.operationId !== undefined
                ) {
                    const key = `${operation.code}|${operation.operationId}`;

                    if (!codeOperationIdOccurrences.has(key)) {
                        codeOperationIdOccurrences.set(key, []);
                    }

                    codeOperationIdOccurrences.get(key)!.push({
                        pathCode,
                        pathOperationId,
                        row: rowCode,
                        col: colCode,
                    });
                }
            });

            codeOperationIdOccurrences.forEach((locations, key) => {
                if (locations.length > 1) {
                    const [code, operationId] = key.split('|');
                    locations.forEach(
                        ({ pathCode, pathOperationId, row, col }) => {
                            errorMap.set(pathCode, {
                                path: pathCode,
                                row,
                                col,
                                messages: [
                                    `Duplicate combination of code (${code}) and operationId (${operationId}) found.`,
                                ],
                            });
                        }
                    );
                }
            });
        }

        // Check for duplicate paramters
        if (Array.isArray(data?.operations)) {
            data.operations.forEach((operation: any, index: number) => {
                if (
                    operation.parameters &&
                    typeof operation.parameters === 'object'
                ) {
                    const keyOccurrences = new Map<
                        string,
                        { path: string; row: number; col: number }[]
                    >();
                    const valueOccurrences = new Map<
                        string,
                        { path: string; row: number; col: number }[]
                    >();

                    Object.entries(operation.parameters).forEach(
                        ([key, value]) => {
                            const path = `/operations/${index}/parameters/${key}`;
                            const pointer = pointers[path];
                            const row = pointer?.value?.line + 1 || 1;
                            const col = pointer?.value?.column + 1 || 1;

                            // Check for duplicate keys
                            if (!keyOccurrences.has(key)) {
                                keyOccurrences.set(key, []);
                            }
                            keyOccurrences.get(key)!.push({ path, row, col });

                            // Check for duplicate values assigned to different keys
                            if (typeof value === 'string') {
                                if (!valueOccurrences.has(value)) {
                                    valueOccurrences.set(value, []);
                                }
                                valueOccurrences
                                    .get(value)!
                                    .push({ path, row, col });
                            }
                        }
                    );

                    // Add errors for duplicate values in different keys
                    valueOccurrences.forEach((locations, value) => {
                        if (locations.length > 1) {
                            locations.forEach(({ path, row, col }) => {
                                errorMap.set(path, {
                                    path,
                                    row,
                                    col,
                                    messages: [
                                        `Duplicate parameter value found: '${value}' used multiple times`,
                                    ],
                                });
                            });
                        }
                    });
                }
            });
        }

        const errors = Array.from(errorMap.values()).map(
            ({ path, row, col, messages }) => ({
                message: messages.join(' '),
                path,
                row,
                col,
                errorObject: {
                    error: messages.join(' '),
                    path,
                    row,
                    col,
                },
            })
        );
        errors.sort((a, b) => a.row - b.row);

        return errors.length > 0
            ? { isValid: false, errors }
            : { isValid: true, errors: [] };
    };
};

export default createValidator;
