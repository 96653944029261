import {
    LayoutService,
    ProjectService,
    AccessValidatorService,
    UsersService,
} from '../services';
import { apiConstants, apiActions } from '../redux/Api';
import { permissionActions } from '../redux/Permissions';
import { projectActions, projectSelectors } from '../redux/Projects';
import { Middleware } from 'redux';
import { ApplicationState } from '../reduxSetup/interfaces';
import { LayoutUpdateContainerDTO, ProjectDTO } from '../services/Dtos';
import {
    FAILED_TO_GET_URL,
    NOT_FOUND,
    ProjectInfo,
    ProjectList,
    ProjectOverview,
} from '../redux/Projects/interfaces';
import { CreatedLayoutDTO } from '../services/Dtos/LayoutDTO';
import { Guid } from 'guid-typescript';
import { Role, RoleId, RoleIds } from '../constants/enums';
import { LayoutFileType } from 'src/constants/layoutFileConstants';
import saveFile from 'src/helpers/saveFile';
import { fileDateFormatter } from 'src/helpers/dateFormatter';
import { AccessValidatorQuery } from 'src/services/interfaces';
import { ProjectPermissions } from 'src/redux/Permissions/interfaces';
import { formatRoleAssignments } from 'src/helpers/roles';
import { operationSettingsActions } from 'src/redux/OperationSettings';
import Snackbar from 'src/utils/snackbar';
import { syncServiceApi } from 'src/services/syncServiceApi';
import { syncServiceApiTag } from 'src/services/tags';
import { layoutServiceSyncServiceApi } from 'src/services/layoutService';

export const createProjectAccessValidatorQuery = (
    subscriptionId: Guid,
    projectId: string
) => {
    const anyInstance = '00000000-0000-0000-0000-000000000000';
    return {
        readProject: `GET:/api/v1.0/subscriptions/${subscriptionId}/projects/${projectId}`,
        editProject: `PUT:/api/v1.0/subscriptions/${subscriptionId}/projects/${projectId}`,
        readUsers: `GET:/api/v1.0/subscriptions/${subscriptionId}/projects/${projectId}/roleassignments`,
        createUsers: `POST:/api/v1.0/subscriptions/${subscriptionId}/projects/${projectId}/roleassignments`,
        addProjectUser: `POST:/api/v1.0/subscriptions/${subscriptionId}/projects/${projectId}/roleassignments/action/userinvite`,
        deleteProjectUser: `DELETE:/api/v1.0/subscriptions/${subscriptionId}/projects/${projectId}/roleAssignments`,
        downloadProjectFiles: `GET:/api/v1.0/subscriptions/${subscriptionId}/projects/${projectId}/layouts/${anyInstance}/files/${anyInstance}/action/downloaduri`,
        downloadProjectUpdates: `POST:/api/v1.1/subscriptions/${subscriptionId}/projects/${projectId}/layouts/${anyInstance}/layoutupdates/action/downloadFile`,
        uploadProjectFilesLayoutDb: `POST:/api/v1.1/subscriptions/${subscriptionId}/projects/${projectId}/layouts/${anyInstance}/layoutdb`,
        uploadProjectFilesDwg: `POST:/api/v1.1/subscriptions/${subscriptionId}/projects/${projectId}/layouts/${anyInstance}/dwg`,
        uploadStationOperations: `POST:/api/v1.1/subscriptions/${subscriptionId}/projects/${projectId}/layouts/${anyInstance}/stationOperations`,
        deleteStationOperations: `DELETE:/api/v1.1/subscriptions/${subscriptionId}/projects/${projectId}/layouts/${anyInstance}/stationOperations`,
        archiveOperationUpdates: `POST:/api/v1.1/subscriptions/${subscriptionId}/projects/${projectId}/layouts/${anyInstance}/layoutupdates/action/archiveoperationupdates`,
    };
};

const projectApiUrl = process.env.REACT_APP_SYNC_SERVICE_API_URL as string;
const syncServiceApiUrl = process.env.REACT_APP_SYNC_SERVICE_API_URL as string;

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const normalizeProjectList = (projects: ProjectDTO[] | string): ProjectList => {
    const result: ProjectList = {};
    if (typeof projects === 'string') return {};

    projects.forEach((p) => {
        result[p.id] = { ...p };
    });

    return result;
};

const projectMiddleware: Middleware<
    {}, // legacy,
    ApplicationState
> = (store) => (next) => async (action) => {
    next(action);

    const dispatch = store.dispatch;
    const state = store.getState();
    const knownAction = action as apiActions.KnownAction;
    const projectService = new ProjectService(projectApiUrl);
    const layoutService = new LayoutService(syncServiceApiUrl);
    const usersService = new UsersService();
    const accessValidatorService = new AccessValidatorService();

    interface ProjectOverviewLocal {
        companyId: string;
        projectName: string;
        projectId: string;
        status: number;
    }

    const handleAccessValidation = (
        subscriptionId: Guid,
        projects: ProjectInfo[]
    ) => {
        let accessValidatorQuery: AccessValidatorQuery = {};
        projects.forEach(({ id }) => {
            accessValidatorQuery[id] = createProjectAccessValidatorQuery(
                subscriptionId,
                id
            );
        });
        accessValidatorService
            .validateAccess(accessValidatorQuery)
            .then((accessValidatorResult) => {
                dispatch(
                    permissionActions.SetProjectsPermissions(
                        accessValidatorResult as unknown as ProjectPermissions
                    )
                );
            });
    };

    switch (knownAction.type) {
        case apiConstants.GET_PROJECT:
            await projectService
                .GetProject(knownAction.subscriptionId, knownAction.guid)
                .then((proj) => {
                    if (proj == NOT_FOUND)
                        dispatch(projectActions.SetSelectedProject(proj));
                    else if (proj as ProjectInfo) {
                        dispatch(
                            projectActions.SetSelectedProject(
                                proj as ProjectInfo
                            )
                        );
                        handleAccessValidation(knownAction.subscriptionId, [
                            proj,
                        ] as ProjectInfo[]);
                    }
                });
            break;

        case apiConstants.GET_PROJECT_ROLE_ASSIGNMENTS:
            await projectService
                .getProjectRoleAssignments(
                    knownAction.subscriptionId,
                    knownAction.projectId
                )
                .then((result) => {
                    if (Array.isArray(result)) {
                        dispatch(
                            projectActions.SetProjectRoleAssignments(
                                knownAction.projectId,
                                formatRoleAssignments(result)
                            )
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            break;

        case apiConstants.GET_PROJECT_OPERATION_SETTINGS:
            await projectService
                .GetProjectOperationSettings(
                    knownAction.subscriptionId,
                    knownAction.projectId
                )
                .then((operationSettingsTemplates) => {
                    if (
                        operationSettingsTemplates &&
                        typeof operationSettingsTemplates !== 'string'
                    ) {
                        dispatch(
                            operationSettingsActions.SetOperationSettings(
                                operationSettingsTemplates
                            )
                        );
                    }
                })
                .catch((error) => {
                    console.error(
                        'GET_PROJECT_OPERATION_SETTINGS error',
                        error
                    );
                });
            break;

        case apiConstants.GET_PROJECTS:
            await projectService
                .GetProjects(knownAction.subscriptionId)
                .then((projects) => {
                    if (projects && (projects as ProjectDTO[])) {
                        const normalizedProjectList =
                            normalizeProjectList(projects);
                        dispatch(
                            projectActions.SetProjects(normalizedProjectList)
                        );
                        handleAccessValidation(
                            knownAction.subscriptionId,
                            projects as ProjectInfo[]
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(projectActions.SetProjects({}));
                });
            break;

        case apiConstants.UPLOAD_EXTERNAL_OPERATIONS:
            dispatch(
                projectActions.AddUploadingLayoutFile({
                    fileName: knownAction.externalOperationsFile.name,
                    fileType: LayoutFileType.ExternalStationOperations,
                    requestId: knownAction.requestId.toString(),
                })
            );
            await layoutService
                .UploadExternalOperationsFile(
                    knownAction.subscriptionId,
                    knownAction.projectId,
                    knownAction.layoutGuid,
                    knownAction.externalOperationsFile
                )
                .then((layoutFile) => {
                    if (
                        layoutFile &&
                        layoutFile.fileName.length > 0 &&
                        layoutFile.fileType ===
                            LayoutFileType.ExternalStationOperations
                    ) {
                        dispatch(
                            projectActions.AddProjectLayoutFile(
                                knownAction.projectId.toString(),
                                layoutFile
                            )
                        );
                    } else console.log('Error with layout file', layoutFile);
                })
                .catch((error) => {
                    alert('Failed to upload external operations file');
                })
                .finally(() =>
                    dispatch(
                        projectActions.RemoveUploadingLayoutFile(
                            knownAction.requestId.toString()
                        )
                    )
                );
            break;

        case apiConstants.DELETE_EXTERNAL_OPERATIONS_FILE:
            await layoutService
                .DeleteExternalOperationsFile(
                    knownAction.subscriptionId,
                    knownAction.projectId,
                    knownAction.layoutGuid
                )
                .then((result) => {
                    knownAction.deletedCallback(true);
                })
                .catch((error) => {
                    alert('Failed to delete external operations file');
                });
            break;

        case apiConstants.UPLOAD_DWG:
            dispatch(
                projectActions.AddUploadingLayoutFile({
                    fileName: knownAction.dwgFile.name,
                    fileType: LayoutFileType.BackgroundDrawing,
                    requestId: knownAction.requestId.toString(),
                })
            );
            await layoutService
                .UploadDwg(
                    knownAction.subscriptionId,
                    knownAction.projectId,
                    knownAction.layoutGuid,
                    knownAction.dwgFile
                )
                .then((layoutFile) => {
                    if (
                        layoutFile &&
                        layoutFile.fileName.length > 0 &&
                        layoutFile.fileType === LayoutFileType.BackgroundDrawing
                    ) {
                        dispatch(
                            projectActions.AddProjectLayoutFile(
                                knownAction.projectId.toString(),
                                layoutFile
                            )
                        );
                    } else console.log('Error with layout file', layoutFile); //TODO: Error handling
                })
                .catch((error) => {
                    //TODO: Replace alert with modal error message
                    alert('Failed to upload dwg');
                })
                .finally(() =>
                    dispatch(
                        projectActions.RemoveUploadingLayoutFile(
                            knownAction.requestId.toString()
                        )
                    )
                );
            break;

        case apiConstants.UPLOAD_LAYOUT_DATABASE:
            dispatch(
                projectActions.AddUploadingLayoutFile({
                    fileName: knownAction.layoutDbFile.name,
                    fileType: LayoutFileType.LayoutDatabase,
                    requestId: knownAction.layoutDbFileRequestId.toString(),
                })
            );
            dispatch(
                projectActions.AddUploadingLayoutFile({
                    fileName: knownAction.wefFile.name,
                    fileType: LayoutFileType.LayoutDatabase,
                    requestId: knownAction.wefFileRequestId.toString(),
                })
            );

            await layoutService
                .UploadLayoutDatabase(
                    knownAction.subscriptionId,
                    knownAction.projectId,
                    knownAction.layoutGuid,
                    knownAction.layoutDbFile
                )
                .then(async (layoutFile) => {
                    if (
                        layoutFile &&
                        layoutFile.fileName.length > 0 &&
                        layoutFile.fileType === LayoutFileType.LayoutDatabase
                    ) {
                        dispatch(
                            projectActions.AddProjectLayoutFile(
                                knownAction.projectId.toString(),
                                layoutFile
                            )
                        );
                        await layoutService
                            .UploadLayoutVisualization(
                                knownAction.subscriptionId,
                                knownAction.projectId,
                                knownAction.layoutGuid,
                                knownAction.wefFile
                            )
                            .then((wefFileInfo) => {
                                dispatch(
                                    projectActions.SetSelectedProjectWefFileInfo(
                                        wefFileInfo
                                    )
                                );
                            })
                            .catch((error) => {
                                //TODO: Replace alert with modal error message
                                alert('Failed to upload wef file');
                            });
                    } else console.log('Error with layout file', layoutFile); //TODO: Error handling
                })
                .catch(() => {
                    //TODO: Replace alert with modal error message
                    alert('Failed to upload layout database');
                });

            dispatch(
                projectActions.RemoveUploadingLayoutFile(
                    knownAction.layoutDbFileRequestId.toString()
                )
            );
            dispatch(
                projectActions.RemoveUploadingLayoutFile(
                    knownAction.wefFileRequestId.toString()
                )
            );
            break;

        case apiConstants.MARK_OPERATIONS_AS_ARCHIVED:
            await layoutService
                .MarkOperationUpdatesAsArchive(
                    knownAction.subscriptionId,
                    knownAction.projectId,
                    knownAction.layoutId,
                    knownAction.operations
                )
                .then(async () => {
                    // To give DB time to update
                    await wait(1000);
                    dispatch(
                        syncServiceApi.util.invalidateTags([
                            syncServiceApiTag.LayoutUpdates,
                        ])
                    );
                });
            break;

        case apiConstants.CREATE_PROJECT:
            await projectService
                .CreateProject(knownAction.subscriptionId, knownAction.project)
                .then(async (newProject) => {
                    if (newProject && (newProject as ProjectOverview)) {
                        const principalId =
                            state.account.accountInfo?.localAccountId;
                        if (principalId === undefined)
                            throw 'missing principalId';
                        await usersService.addRoleAssignment(
                            `/subscriptions/${
                                knownAction.subscriptionId
                            }/projects/${
                                (newProject as ProjectOverview).projectId
                            }`,
                            RoleIds[Role.CirrusLayoutOwner].toString(),
                            principalId
                        );
                        knownAction.createdCallback(
                            (newProject as ProjectOverview).projectId
                        );
                    }
                })
                .catch((error) => {
                    console.log("Couldn't create project, " + error);
                    knownAction.createdCallback();
                });
            break;

        case apiConstants.EDIT_PROJECT:
            await projectService
                .EditProject(knownAction.subscriptionId, knownAction.project)
                .then((proj: ProjectOverview) => {
                    knownAction.editedCallback(knownAction.project.projectId);
                    if (proj.status === 1) {
                        Snackbar.success(
                            'Snackbar.ProjectSetToFinished.Success'
                        );
                    } else
                        Snackbar.success(
                            'Snackbar.ProjectSetToOngoing.Success'
                        );
                })
                .catch((error) => {
                    console.log("Couldn't edit project, " + error);
                    knownAction.editedCallback();
                });
            break;
        case apiConstants.DELETE_PROJECT:
            await projectService
                .DeleteProject(
                    knownAction.subscriptionId,
                    knownAction.projectId
                )
                .then(() => {
                    knownAction.deletedCallback(true);
                    Snackbar.success('Snackbar.DeleteProject.Success');
                })
                .catch((error) => {
                    console.log("Couldn't delete project, " + error);
                    knownAction.deletedCallback(false);
                });
            break;
        case apiConstants.CREATE_LAYOUT_WITH_NEW_SITE_AND_SYSTEM:
            await layoutService
                .CreateLayoutWithNewSiteAndSystem(
                    knownAction.subscriptionId,
                    knownAction.siteName,
                    knownAction.systemName,
                    knownAction.layoutName,
                    knownAction.projectId
                )
                .then((result) => {
                    const layout = result as CreatedLayoutDTO;
                    if (layout && (layout as CreatedLayoutDTO))
                        knownAction.createdCallback(layout.projectId);
                })
                .catch((error) => {
                    knownAction.createdCallback();
                    alert('Failed to create layout'); // TODO Remove temp alert
                    console.log("Couldn't create layout, " + error);
                });
            break;

        case apiConstants.GET_LAYOUT_FILE_DOWNLOAD_LINK:
            await projectService
                .GetLayoutFileDownloadLink(
                    knownAction.subscriptionId,
                    knownAction.projectId,
                    knownAction.layoutId,
                    knownAction.layoutFileId
                )
                .then((layoutFileLink) => {
                    dispatch(
                        projectActions.SetLayoutFileDownloadLink(
                            layoutFileLink ? new URL(layoutFileLink) : undefined
                        )
                    );
                })
                .catch(() => {
                    //TODO: Replace alert with modal error message
                    alert('Failed to download file');
                    dispatch(
                        projectActions.SetLayoutFileDownloadLink(
                            FAILED_TO_GET_URL
                        )
                    );
                });
            break;

        case apiConstants.DOWNLOAD_LAYOUT_UPDATES:
            await layoutService
                .DownloadLayoutUpdates(
                    knownAction.subscriptionId,
                    knownAction.projectId,
                    knownAction.layoutId,
                    knownAction.downloadUpdatesCommand
                )
                .then((result) => {
                    const filename = `Update_Entities_from _Cirrus_${fileDateFormatter()}.json`;
                    saveFile(filename, JSON.stringify(result));
                    dispatch(
                        syncServiceApi.util.invalidateTags([
                            syncServiceApiTag.LayoutUpdates,
                        ])
                    );
                });
            break;
    }
};

export default projectMiddleware;
