import { Button, ButtonSize, Variant } from '@ndc/react-component-library';
import { useTranslation } from 'src/hooks';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { projectSelectors } from 'src/redux/Projects';
import { NOT_FOUND } from 'src/redux/Projects/interfaces';

const translationKeys = require('src/translations').default;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 9px;
`;

const ButtonContainer = styled.div`
    align-self: flex-end;
    margin-top: 40px;
    margin-right: -70px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;
`;

export type Props = {
    deleteStationOperations: () => void;
    closeModal: () => void;
};

export type Actions = {};

const DeleteExternalStationOperationsModal = ({
    deleteStationOperations,
    closeModal,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const selectedProject = useSelector(projectSelectors.getSelectedProject);

    const onDeleteProject = () => {
        closeModal();
        deleteStationOperations();
    };
    if (selectedProject != NOT_FOUND && selectedProject != null) {
        return (
            <Container>
                {getTranslation('ProjectViewer.DeleteFileModal.Message', {
                    projectTitle: selectedProject?.commissioningProject,
                })}
                <ButtonContainer>
                    <Button
                        variant={Variant.secondary}
                        size={ButtonSize.small}
                        key={'cancel-delete-operation-file'}
                        data-testid="cancel-delete-operation-file"
                        onClick={closeModal}
                    >
                        {getTranslation(translationKeys.General_Cancel)}
                    </Button>
                    <Button
                        size={ButtonSize.small}
                        key={'delete-operation-file'}
                        data-testid="delete-operation-file"
                        onClick={onDeleteProject}
                    >
                        {getTranslation(
                            'ProjectViewer.FileUpload.DeleteFile.Label'
                        )}
                    </Button>
                </ButtonContainer>
            </Container>
        );
    }
    return null;
};

export default DeleteExternalStationOperationsModal;
