import { getTokenPopup } from '../auth/authUtils';
import { customApiScopes } from '../auth/apiScopes';
import api from './api';
import {
    LayoutUpdateContainerDTO,
    DownloadUpdatesCommand,
    LayoutVisualizationDataDTO,
    LayoutBackgroundDrawingDTO,
} from './Dtos';
import { backends } from '../common/backends';
import { Guid } from 'guid-typescript';
import { LayoutFile } from '../redux/Projects/interfaces';
import { WefFileInfoDTO } from './Dtos/WefFileInfoDTO';
import { CreateLayoutCommand } from './Dtos/CreateLayoutCommand';
import { syncServiceApi } from './syncServiceApi';
import { LayoutUpdateDTO } from './Dtos/LayoutUpdate';
import { syncServiceApiTag } from './tags';

export default class LayoutService {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    GetUpdates(subscriptionId: Guid, projectId: Guid, layoutGuid: Guid) {
        const requestConfig = customApiScopes.syncServiceApiRequestConfig;
        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.1',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<LayoutUpdateContainerDTO>({
                url: `${backends.syncServiceApi}/api${
                    backends.syncServiceApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}/layouts/${layoutGuid.toString()}/layoutupdates/action/all`,
                method: 'GET',
                options,
            });
        });
    }

    UploadLayoutDatabase(
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid,
        layoutDbFile: File
    ) {
        const requestConfig = customApiScopes.syncServiceApiRequestConfig;
        return getTokenPopup(requestConfig).then((accessToken) => {
            const body = new FormData();
            body.append('layoutFile', layoutDbFile);
            body.append('layoutId', layoutId.toString());

            const options = {
                headers: {
                    Accept: 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body,
            };

            return api({
                url: `${backends.syncServiceApi}/api${
                    backends.syncServiceApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}/layouts/${layoutId.toString()}/layoutdb`,
                method: 'POST',
                options,
            }).then((result) => {
                return result as LayoutFile;
            });
        });
    }

    UploadDwg(
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid,
        dwgFile: File
    ) {
        const requestConfig = customApiScopes.syncServiceApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const body = new FormData();
            body.append('dwgFile', dwgFile);
            body.append('layoutId', layoutId.toString());

            const options = {
                headers: {
                    Accept: 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body,
            };

            return api({
                url: `${backends.syncServiceApi}/api${
                    backends.syncServiceApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}/layouts/${layoutId.toString()}/dwg`,
                method: 'POST',
                options,
            }).then((result) => {
                return result as LayoutFile;
            });
        });
    }

    UploadExternalOperationsFile(
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid,
        externalOperationsFile: File
    ) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const body = new FormData();
            body.append('file', externalOperationsFile);
            body.append('layoutId', layoutId.toString());

            const options = {
                headers: {
                    Accept: 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body,
            };

            return api({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}/layouts/${layoutId.toString()}/stationoperations`,
                method: 'POST',
                options,
            }).then((result) => {
                return result as LayoutFile;
            });
        });
    }

    DeleteExternalOperationsFile(
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid
    ) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const body = new FormData();
            body.append('layoutId', layoutId.toString());
            const options = {
                headers: {
                    Accept: 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body,
            };
            return api({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}/layouts/${layoutId.toString()}/stationoperations`,
                method: 'DELETE',
                options,
            });
        });
    }

    UploadLayoutVisualization(
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid,
        wefFile: File
    ) {
        const requestConfig = customApiScopes.projectApiRequestConfig;
        return getTokenPopup(requestConfig).then((accessToken) => {
            const body = new FormData();
            body.append('wefFile', wefFile);
            body.append('layoutId', layoutId.toString());

            const options = {
                headers: {
                    Accept: 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body,
            };

            return api({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}/layouts/${layoutId.toString()}/visualizationdata`,
                method: 'POST',
                options,
            }).then((result) => {
                return result as WefFileInfoDTO;
            });
        });
    }

    CreateLayoutWithNewSiteAndSystem(
        subscriptionId: Guid,
        siteName: string,
        systemName: string,
        layoutName: string,
        projectId: Guid
    ) {
        const requestConfig = customApiScopes.projectApiRequestConfig;
        const createLayoutCommand: CreateLayoutCommand = {
            siteName,
            systemName,
            layoutName,
        };

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(createLayoutCommand),
            };

            return api({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}/action/createlayout`,
                method: 'POST',
                options,
            });
        });
    }

    DownloadLayoutUpdates(
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid,
        downloadUpdatesCommand: DownloadUpdatesCommand
    ) {
        const requestConfig = customApiScopes.syncServiceApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.1',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(downloadUpdatesCommand),
            };

            return api({
                url: `${backends.syncServiceApi}/api${
                    backends.syncServiceApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}/layouts/${layoutId.toString()}/layoutupdates/action/downloadFile`,
                method: 'POST',
                options,
            });
        });
    }

    MarkOperationUpdatesAsArchive(
        subscriptionId: Guid,
        projectId: Guid,
        layoutGuid: Guid,
        operations: any
    ) {
        const requestConfig = customApiScopes.syncServiceApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(operations.operationUpdates),
            };

            return api({
                url: `${backends.syncServiceApi}/api${
                    backends.syncServiceApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}/layouts/${layoutGuid.toString()}/layoutupdates/action/archiveoperationupdates`,
                method: 'POST',
                options,
            });
        });
    }

    GetLayoutVisualization(
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid
    ) {
        const requestConfig = customApiScopes.syncServiceApiRequestConfig;
        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<LayoutVisualizationDataDTO>({
                url: `${backends.syncServiceApi}/api${
                    backends.syncServiceApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}/layouts/${layoutId.toString()}/visualizationdata`,
                method: 'GET',
                options,
            });
        });
    }

    GetLayoutBackgroundDrawing(
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid
    ) {
        const requestConfig = customApiScopes.syncServiceApiRequestConfig;
        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<LayoutBackgroundDrawingDTO>({
                url: `${backends.syncServiceApi}/api${
                    backends.syncServiceApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}/layouts/${layoutId.toString()}/backgrounddrawing`,
                method: 'GET',
                options,
            });
        });
    }
}

export const layoutServiceSyncServiceApi = syncServiceApi.injectEndpoints({
    endpoints: (builder) => ({
        getNewLayoutUpdates: builder.query<
            LayoutUpdateDTO,
            { subscriptionId: string; projectId: string; layoutId: string }
        >({
            query: (arg) => {
                const { subscriptionId, projectId, layoutId } = arg;
                return `${backends.syncServiceApiVersion.substring(
                    1
                )}/subscriptions/${subscriptionId}/projects/${projectId}/layouts/${layoutId}/layoutupdates/action/new`;
            },
            transformResponse: (
                response: LayoutUpdateContainerDTO | null
            ): LayoutUpdateDTO => ({
                operations: response?.operationUpdates ?? [],
                points: response?.pointUpdates ?? [],
                segments: response?.segmentUpdates ?? [],
                drawingLines: response?.drawingLineUpdates ?? [],
            }),
            providesTags: [
                syncServiceApiTag.LayoutUpdates,
                syncServiceApiTag.NewLayoutUpdates,
            ],
        }),
        getArchivedLayoutUpdates: builder.query<
            LayoutUpdateDTO,
            { subscriptionId: string; projectId: string; layoutId: string }
        >({
            query: (arg) => {
                const { subscriptionId, projectId, layoutId } = arg;
                return `${backends.syncServiceApiVersion.substring(
                    1
                )}/subscriptions/${subscriptionId}/projects/${projectId}/layouts/${layoutId}/layoutupdates/action/archived`;
            },
            transformResponse: (
                response: LayoutUpdateContainerDTO | null
            ): LayoutUpdateDTO => ({
                operations: response?.operationUpdates ?? [],
                points: response?.pointUpdates ?? [],
                segments: response?.segmentUpdates ?? [],
                drawingLines: response?.drawingLineUpdates ?? [],
            }),
            providesTags: [
                syncServiceApiTag.LayoutUpdates,
                syncServiceApiTag.ArchivedLayoutUpdates,
            ],
        }),
    }),
    overrideExisting: false,
});

export const { useGetNewLayoutUpdatesQuery, useGetArchivedLayoutUpdatesQuery } =
    layoutServiceSyncServiceApi;
