import { Reducer, Action } from 'redux';
import * as projectActions from './projectActions';
import * as projectConstants from './projectConstants';
import { NOT_FOUND, ProjectInfo, ProjectState } from './interfaces';
import { cloneDeep, reject } from 'lodash';

export const initialState: ProjectState = {
    projects: undefined,
    selectedLayoutUpdateContainer: undefined,
    selectedProject: undefined,
    uploadingLayoutFiles: [],
    layoutFileDownloadLink: undefined,
};

const getSelectedProject = (currentState: ProjectState) => {
    const projectList = currentState.projects;
    const currentSelectedProject = currentState.selectedProject;
    if (
        projectList &&
        currentSelectedProject &&
        currentSelectedProject !== NOT_FOUND
    ) {
        return projectList[currentSelectedProject.id ?? ''];
    }
    return undefined;
};

export const reducer: Reducer<ProjectState> = (
    state: ProjectState = initialState,
    incomingAction: Action
): ProjectState => {
    const action = incomingAction as projectActions.KnownAction;

    const currentState = cloneDeep(state);

    switch (action.type) {
        case projectConstants.SET_PROJECTS:
            return { ...state, projects: action.projects };
        case projectConstants.SET_SELECTED_PROJECT:
            if (
                action.selectedProject === undefined ||
                action.selectedProject === NOT_FOUND
            )
                return { ...state, selectedProject: action.selectedProject };
            else {
                return {
                    ...state,
                    projects: {
                        ...state.projects,
                        ...{
                            [action.selectedProject.id]: action.selectedProject,
                        },
                    },
                    selectedProject:
                        state.selectedProject === undefined ||
                        state.selectedProject === NOT_FOUND
                            ? { id: action.selectedProject.id }
                            : {
                                  ...state.selectedProject,
                                  id: action.selectedProject.id,
                              },
                };
            }
        case projectConstants.ADD_UPLOADING_LAYOUTFILE:
            return {
                ...state,
                uploadingLayoutFiles: [
                    ...state.uploadingLayoutFiles,
                    action.fileUpload,
                ],
            };
        case projectConstants.REMOVE_UPLOADING_LAYOUTFILE:
            return {
                ...state,
                uploadingLayoutFiles: reject(state.uploadingLayoutFiles, {
                    requestId: action.requestId,
                }),
            };
        case projectConstants.SET_LAYOUTFILE_DOWNLOAD_LINK:
            return { ...state, layoutFileDownloadLink: action.url };
        case projectConstants.SET_SELECTED_PROJECT_WEFFILEINFO:
            if (currentState) {
                let currentSelectedProject = getSelectedProject(currentState);
                if (currentSelectedProject) {
                    currentSelectedProject.wefFileInfo = action.wefFileInfo;
                }
            }
            return currentState;
        case projectConstants.SET_PROJECT_ROLE_ASSIGNMENTS:
            if (currentState) {
                if (
                    currentState.projects &&
                    !!currentState.projects[action.projectId.toString()]
                ) {
                    currentState.projects[action.projectId.toString()].users =
                        action.roleAssignments;
                }
            }
            return currentState;
        case projectConstants.ADD_PROJECT_LAYOUT_FILE:
            if (currentState) {
                if (
                    currentState.projects &&
                    !!currentState.projects[action.projectId]
                ) {
                    currentState.projects[action.projectId].layoutFiles.unshift(
                        action.layoutFile
                    );
                }
            }
            return currentState;
        case projectConstants.REMOVE_PROJECT_LAYOUT_FILE:
            return currentState;
        default:
            return state;
    }
};
