export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_SELECTED_PROJECT_USERS = 'SET_SELECTED_PROJECT_USERS';
export const SET_SELECTED_PROJECT_WEFFILEINFO =
    'SET_SELECTED_PROJECT_WEFFILEINFO';
export const REMOVE_FROM_SELECTED_PROJECT_USERS =
    'REMOVE_FROM_SELECTED_PROJECT_USERS';
export const UPDATE_IN_SELECTED_PROJECT_USERS =
    'UPDATE_IN_SELECTED_PROJECT_USERS';
export const ADD_UPLOADING_LAYOUTFILE = 'ADD_UPLOADING_LAYOUTFILE';
export const REMOVE_UPLOADING_LAYOUTFILE = 'REMOVE_UPLOADING_LAYOUTFILE';
export const SET_LAYOUTFILE_DOWNLOAD_LINK = 'SET_LAYOUTFILE_DOWNLOAD_LINK';
export const RESET_LAYOUTFILE_DOWNLOAD_LINK = 'RESET_LAYOUTFILE_DOWNLOAD_LINK';
export const SET_PROJECT_ROLE_ASSIGNMENTS = 'SET_PROJECT_ROLE_ASSIGNMENTS';
export const ADD_PROJECT_LAYOUT_FILE = 'ADD_PROJECT_LAYOUT_FILE';
export const REMOVE_PROJECT_LAYOUT_FILE = 'REMOVE_PROJECT_LAYOUT_FILE';
