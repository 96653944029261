import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import useTranslation from 'src/hooks/useTranslation';
import {
    Button,
    ButtonSize,
    ButtonVariant,
} from '@ndc/react-component-library';
import { FileUploadButton } from './LayoutFileComponent';
import { UploadItem } from './FileUploadComponent';
import {
    LayoutFileConfig,
    LayoutFileType,
    ValidationErrorType,
} from 'src/constants/layoutFileConstants';
import { Guid } from 'guid-typescript';

const TableContainer = styled.div`
    margin-top: 20px;
    padding-right: 5px;
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: thin;
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px; /* Space between rows */
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    word-break: break-word;
    gap: 10px;
`;

const Cell = styled.div<{ align?: string }>`
    flex: 1;
    text-align: ${({ align }) => align || 'left'};
    &:nth-child(1) {
        flex: 0.3; /* Smaller width for Row */
    }
    &:nth-child(2) {
        flex: 0.6; /* Larger width for Parameter */
        overflow-wrap: break-word;
        white-space: pre-wrap;
    }
`;

const Header = styled(Row)`
    padding: 0;
    font-weight: bold;
    box-shadow: none;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #fff;
`;

const ErrorMessage = styled.span`
    color: #cc0e0e;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 9px;
    width: 420px;
`;

const ButtonContainer = styled.div`
    align-self: flex-end;
    margin-top: 20px;
    margin-right: 5px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;
`;

export type Props = {
    errors?: any;
    type:
        | ValidationErrorType.ParsingError
        | ValidationErrorType.ValidationError;
    closeModal: () => void;
    uploadHandler: (files: File[]) => void;
};

export type Actions = {};

const ValidationErrorModal = ({
    errors,
    type,
    closeModal,
    uploadHandler,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const dispatch = useDispatch();

    let uploadItems = [] as UploadItem[];
    uploadItems.push({
        id: Guid.create(),
        label: getTranslation('ProjectViewer.FileUpload.Select.File', {
            fileType: LayoutFileConfig.externalStationOperationsFile.fileType,
        }),
        accept: LayoutFileConfig.externalStationOperationsFile.accept,
        maxSize: LayoutFileConfig.externalStationOperationsFile.maxSize,
    } as UploadItem);

    const SubTitle = () => {
        return (
            <Typography variant="body1" data-testid="subtitle">
                {type === ValidationErrorType.ValidationError
                    ? getTranslation(
                          'ProjectViewer.ValidationErrorModal.ValidationErrorSubTitle'
                      )
                    : getTranslation(
                          'ProjectViewer.ValidationErrorModal.ParsingErrorSubTitle'
                      )}
            </Typography>
        );
    };

    return (
        <Container>
            <SubTitle />
            {type === ValidationErrorType.ValidationError && (
                <TableContainer>
                    <Table>
                        {/* Table Header */}
                        <Header>
                            <Cell>
                                {getTranslation(
                                    'ProjectViewer.ValidationErrorModal.RowHeader.Label'
                                )}
                            </Cell>
                            <Cell style={{ paddingLeft: '5px' }}>
                                {getTranslation(
                                    'ProjectViewer.ValidationErrorModal.ParameterHeader.Label'
                                )}
                            </Cell>
                            <Cell align="left">
                                {getTranslation(
                                    'ProjectViewer.ValidationErrorModal.ErrorMessageHeader.Label'
                                )}
                            </Cell>
                        </Header>
                        {/* Table Rows */}
                        {errors.map((error: any, index: any) => (
                            <Row key={index}>
                                <Cell>{error.row}</Cell>
                                <Cell>{error.path}</Cell>
                                <Cell
                                    align="left"
                                    style={{ paddingLeft: '1px' }}
                                >
                                    <ErrorMessage>{error.error}</ErrorMessage>
                                </Cell>
                            </Row>
                        ))}
                    </Table>
                </TableContainer>
            )}
            <ButtonContainer>
                <Button
                    variant={ButtonVariant.secondary}
                    size={ButtonSize.small}
                    onClick={() => closeModal()}
                    data-testid="upload-button"
                >
                    {getTranslation('General.Close')}
                </Button>
                <FileUploadButton
                    variant={ButtonVariant.primary}
                    size={ButtonSize.small}
                    data-testid={'external-station-operation-file'}
                    dispatch={dispatch}
                    fileType={LayoutFileType.ExternalStationOperations}
                    uploadModalTitle={getTranslation(
                        'ProjectViewer.FileUpload.Title.ExternalStationOperations'
                    )}
                    uploadItems={uploadItems}
                    uploadHandler={uploadHandler}
                    closeParentModal={closeModal}
                >
                    {getTranslation(
                        'ProjectViewer.ValidationErrorModal.UploadButton.Label'
                    )}
                </FileUploadButton>
            </ButtonContainer>
        </Container>
    );
};

export default ValidationErrorModal;
