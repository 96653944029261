import React from 'react';
import { useDispatch } from 'react-redux';
import { Guid } from 'guid-typescript';
import { modalActions } from 'src/redux/Modals';
import WarningIcon from 'src/assets/icons/WarningIcon';
import ValidationErrorModal from './ValidationErrorModal';
import createValidator from 'src/utils/validateSchema';
import * as schema from 'src/assets/json_schema.json';
import { ModalType } from 'src/constants/enums';
import { ValidationErrorType } from 'src/constants/layoutFileConstants';
import useTranslation from 'src/hooks/useTranslation';

type ExternalOperationFileHandlerProps = {
    UploadExternalStationOperation: (file: File) => void;
};

const ExternalOperationFileHandler = ({
    UploadExternalStationOperation,
}: ExternalOperationFileHandlerProps) => {
    const validator = createValidator(schema);
    const dispatch = useDispatch();
    const getTranslation = useTranslation();

    const handleExternalOperationFileSubmit = async (files: File[]) => {
        const reader = new FileReader();

        reader.onload = function (event: any) {
            try {
                const rawJson = event.target.result; // Capture raw JSON string
                const jsonContent = JSON.parse(rawJson); // Parse the JSON file content

                const { isValid, errors } = validator(jsonContent, rawJson);
                if (!isValid) {
                    const modalId = Guid.create();
                    dispatch(
                        modalActions.OpenModal({
                            id: modalId,
                            heading: getTranslation(
                                'ProjectViewer.ValidationErrorModal.Header'
                            ),
                            headingIcon: <WarningIcon />,
                            type: ModalType.Custom,
                            content: (
                                <ValidationErrorModal
                                    type={ValidationErrorType.ValidationError}
                                    errors={errors?.map(
                                        (err) => err.errorObject
                                    )}
                                    closeModal={() =>
                                        dispatch(
                                            modalActions.CloseModal(modalId)
                                        )
                                    }
                                    uploadHandler={
                                        handleExternalOperationFileSubmit
                                    }
                                />
                            ),
                        })
                    );
                } else {
                    UploadExternalStationOperation(files[0]);
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
                const modalId = Guid.create();
                dispatch(
                    modalActions.OpenModal({
                        id: modalId,
                        heading: getTranslation(
                            'ProjectViewer.ValidationErrorModal.Header'
                        ),
                        headingIcon: <WarningIcon />,
                        type: ModalType.Custom,
                        content: (
                            <ValidationErrorModal
                                type={ValidationErrorType.ParsingError}
                                closeModal={() =>
                                    dispatch(modalActions.CloseModal(modalId))
                                }
                                uploadHandler={
                                    handleExternalOperationFileSubmit
                                }
                            />
                        ),
                    })
                );
            }
        };

        reader.onerror = function () {
            console.error('Error reading file:', reader.error);
        };

        reader.readAsText(files[0]); // Read file as text
    };

    return { handleExternalOperationFileSubmit };
};

export default ExternalOperationFileHandler;
