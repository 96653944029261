import { connect } from 'react-redux';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import ProjectViewer, { Props, Actions } from './components/ProjectViewer';
import { projectSelectors, projectActions } from 'src/redux/Projects';
import { ProjectOverview } from 'src/redux/Projects/interfaces';
import { apiActions } from 'src/redux/Api';
import { Guid } from 'guid-typescript';
import { subscriptionSelectors } from 'src/redux/Subscriptions';
import { operationSettingsSelectors } from 'src/redux/OperationSettings';
import {
    DownloadUpdatesCommand,
    ArchiveOperationUpdatesCommand,
} from 'src/services/Dtos/DownloadUpdatesCommand';
import { modalActions } from 'src/redux/Modals';
import { ModalType } from 'src/constants/enums';
import { ReactNode } from 'react';

const mapStateToProps: (state: ApplicationState) => Props = (state) => {
    return {
        selectedProject: projectSelectors.getSelectedProject(state),
        subscriptionId: subscriptionSelectors.getSubscriptionId(state),
        uploadingLayoutFiles: projectSelectors.getUploadingLayoutFiles(state),
        layoutFileDownloadLink:
            projectSelectors.getLayoutFileDownloadLink(state),
        operationSettings:
            operationSettingsSelectors.getOperationSettings(state),
    };
};

const mapDispatchToProps: Actions = {
    CreateLayout: (
        subscriptionId: Guid,
        siteName: string,
        systemName: string,
        layoutName: string,
        projectId: Guid,
        createdCallback: (projectId?: string) => void
    ) =>
        apiActions.createLayoutWithNewSiteAndSystem(
            subscriptionId,
            siteName,
            systemName,
            layoutName,
            projectId,
            createdCallback
        ),
    ResetSelectedProject: () => projectActions.ResetSelectedProject(),
    GetProject: (subscriptionId: Guid, guid: string) =>
        apiActions.getProject(subscriptionId, Guid.parse(guid)),
    UploadLayoutDb: (
        subscriptionId: Guid,
        projectId: string,
        layoutGuid: string,
        layoutDbFile: File,
        wefFile: File
    ) =>
        apiActions.uploadLayoutDb(
            subscriptionId,
            Guid.parse(projectId),
            Guid.parse(layoutGuid),
            layoutDbFile,
            wefFile
        ),
    UploadDwg: (
        subscriptionId: Guid,
        projectId: string,
        layoutGuid: string,
        file: File
    ) =>
        apiActions.uploadDwg(
            subscriptionId,
            Guid.parse(projectId),
            Guid.parse(layoutGuid),
            file
        ),
    UploadExternalStationOperation: (
        subscriptionId: Guid,
        projectId: string,
        layoutGuid: string,
        file: File
    ) =>
        apiActions.uploadExternalOperations(
            subscriptionId,
            Guid.parse(projectId),
            Guid.parse(layoutGuid),
            file
        ),

    DeleteExternalStationOperation: (
        subscriptionId: Guid,
        projectId: string,
        layoutGuid: string,
        deleteCallBack: (status?: boolean) => void
    ) =>
        apiActions.deleteExternalOperationsFile(
            subscriptionId,
            Guid.parse(projectId),
            Guid.parse(layoutGuid),
            deleteCallBack
        ),
    GetLayoutFileDownloadLink: (
        subscriptionId: Guid,
        projectId: string,
        layoutId: string,
        layoutFileId: string
    ) =>
        apiActions.getLayoutFileDownloadLink(
            subscriptionId,
            Guid.parse(projectId),
            Guid.parse(layoutId),
            Guid.parse(layoutFileId)
        ),
    ResetLayoutFileDownloadLink: () =>
        projectActions.ResetLayoutFileDownloadLink(),
    DownloadLayoutUpdates: (
        subscriptionId: Guid,
        projectId: string,
        layoutId: string,
        downloadUpdatesCommand: DownloadUpdatesCommand
    ) =>
        apiActions.downloadLayoutUpdates(
            subscriptionId,
            Guid.parse(projectId),
            Guid.parse(layoutId),
            downloadUpdatesCommand
        ),

    MarkOperationUpdatesAsDownloaded: (
        subscriptionId: Guid,
        projectId: string,
        layoutId: string,
        archiveUpdatesCommand: ArchiveOperationUpdatesCommand
    ) =>
        apiActions.markOperationsAsArchived(
            subscriptionId,
            Guid.parse(projectId),
            Guid.parse(layoutId),
            archiveUpdatesCommand
        ),
    GetOperationSettings: (subscriptionId: Guid, projectId: string) =>
        apiActions.getProjectOperationSettings(subscriptionId, projectId),
    EditProject: (
        subscriptionId: Guid,
        projectOverview: ProjectOverview,
        finishedCallback: (projectId?: string) => void
    ) =>
        apiActions.editProject(
            subscriptionId,
            projectOverview,
            finishedCallback
        ),
    DeleteProject: (
        subscriptionId: Guid,
        projectId: string,
        deleteCallback: (status: boolean) => void
    ) => apiActions.deleteProject(subscriptionId, projectId, deleteCallback),
    showModalWithContents: (id: Guid, heading: string, content: ReactNode) =>
        modalActions.OpenModal({
            id,
            heading,
            type: ModalType.Custom,
            content,
        }),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectViewer);
